import React, { Component } from 'react';
// bootstrap components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class SubmissionInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <p className="support-copy font-italic">
              Note: Uploading files does not constitute submission and no partial submissions will be considered. A proposal is not submitted unless the bidder selects the “Submit Proposal” button, when permitted. Once your proposal is submitted, a bidder must select the “Withdraw Proposal” button before any submitted files can be deleted or replaced.

              Bidders are reminded that for a proposal to be considered complete and eligible for consideration in PSE’s 2023 DSS RFP, both a confirmed proposal submission and receipt of your bid fee are required. Bid fees are assessed only on the installed nameplate capacity of the proposal(s) submitted by the bidder. Please refer to Section 6 in the RFP document for more details.

              For other questions or additional support, please contact us using the link in the footer.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SubmissionInformation;

