import React, { Component } from 'react';
// component styles
import './index.css';
// bootstrap components
import Container from 'react-bootstrap/Container';

class DownloadUserGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='reference-document-section section'>
        <Container className='text-center'>
          <h1>DSS RFP Proposal Upload and Submission</h1>
          <p className='text-muted'>
            Thank you for your interest in Puget Sound Energy’s 2023 DSS RFP!
            <br />
            Please familiarize yourself with the RFP Website Users’ Guide to
            help guide you through this website.
          </p>
          <a
            className='btn btn-primary btn-lg shadow'
            href={`${process.env.PUBLIC_URL}/documents/DSS RFP Automation - Users Guide.pdf`}
            role='button'
          >
            <i className='bi bi-download mr-2'></i>Download User Guide
          </a>
          <p className='text-muted mb-0 mt-3'>
            Refer to{' '}
            <a href='https://pugetsoundenergy.maps.arcgis.com/apps/webappviewer/index.html?id=980fc190ffd648489a492f8363a1d2cc'>
              Hosting Capacity Web Application (arcgis.com)
            </a>{' '}
            for details on locational value of projects.
          </p>
        </Container>
      </div>
    );
  }
}

export default DownloadUserGuide;
