import React from 'react';
import PropTypes from 'prop-types';
// component styles
import './index.css';
// bootstrap components
import Spinner from 'react-bootstrap/Spinner'

/**
 * checks the props provided to component to determine if the component needs to re-render
 * @param {object} prevProps - previous props provided to component
 * @param {object} nextProps - new props to render component with
 * @returns boolean to determine if the component should render
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.validated !== prevProps.validated && nextProps.error !== prevProps.error) {
    return true // rerender component
  }
  return false  // don't rerender component
}

const Validating = React.memo(({ validated, error, inProgress }) => {
  const [show, setShow] = React.useState('invisible')

  React.useEffect(() => {
    setTimeout(() => {
      setShow('visible')
    }, 500)
  }, []);

  return (
    <div>

      {validated &&
        <>
          <span
            className={`text-success mr-1 ${show}`}
          >
            Success
          </span>
          <i
            className={`bi bi-circle-fill text-success ${show}`}
          ></i>
        </>
      }

      {error &&
        <>
          <span
            className={`text-danger mr-1 ${show}`}
          >
            Error
            </span>
          <i
            className={`bi bi-circle-fill text-danger ${show}`}
          ></i>
        </>
      }

      {!validated && !error &&
        // {inProgress &&
        <>
          <span
            className={`text-warning mr-1 ${show}`}
          >
            In Progress
            </span>
          <Spinner
            animation="grow"
            size="sm"
            variant="warning"
            className={show}
          />
        </>
      }

    </div>
  )
}, areEqual)

Validating.propTypes = {
  validated: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
}


export default Validating

