import React, { Component } from 'react';
// component styles
import './index.css';
// bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        // fluid
        // className="my-4 py-4 text-center"
        className="info-section section"
      >
        <Container
          className="text-center"
          // className="text-center pb-5 mb-5"
        >
          <Row>
            <Col>
              <i className="large-icon bi bi-folder"></i>
              <p>Below, is a workspace to help organize the files needed to assemble your proposal.  All bids must be submitted through this website.</p>
            </Col>
            <Col>
              <i className="large-icon bi bi-bootstrap-reboot"></i>
              <p>A bidder’s proposal(s) may be modified, withdrawn, submitted, or resubmitted as many times as desired up to the submission deadline.</p>
            </Col>
            <Col>
              <i className="large-icon bi bi-shield-lock"></i>
              <p>After the submission deadline, bidders will no longer have the ability to make any modifications to their submitted proposal(s).</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Information;
