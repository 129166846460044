/* eslint-disable import/order */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
// styles
import './index.css';
// components
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import IdleTimer from 'react-idle-timer';
// sections
import Information from '../../sections/Information';
import DownloadUserGuide from '../../sections/DownloadUserGuide';
import UploadFileForm from '../../sections/UploadFileForm';
// helpers
import ConsoleHelper from '../../helpers/ConsoleHelper';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSessionExpirationNotification: false,
      showLogoutNotification: false,
      logoutTimer: null,
      sessionTimeoutDurationMins: 20,
      sessionFinalNoticeDurationMins: 1,
      resetUserMemory: false, // to trigger state cleanup in FilePicker
    };

    this.idleTimer = null;
    this.handleModalOnClick = this.handleModalOnClick.bind(this);
    this.promptAutoUserSignOut = this.promptAutoUserSignOut.bind(this);
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {
    clearTimeout(this.state.logoutTimer);
  }

  componentDidUpdate() {}

  /**
   * Resets timer that will perform the global sign out
   * Hides expiration notification modal
   */
  handleModalOnClick = () => {
    clearTimeout(this.state.logoutTimer);
    this.setState({
      logoutTimer: null,
      showSessionExpirationNotification: false,
    });
  };

  /**
   * Creates a timer that will automatically logout the user after 1 minute
   * Shows a modal popup that will require action from user in order to stay signed into the site.
   */
  promptAutoUserSignOut = () => {
    this.setState({
      showSessionExpirationNotification: true,
    });

    // set final timer for user to stay logged into session
    let logoutTimer = setTimeout(() => {
      // update user state in parent component
      this.props.updateUserCallBack(null);
      this.props.updateAuthStateCallBack(Auth.SignedOut);
      this.props.updateShowLogoutNotificationCallBack(true);
      // clear timer in state
      clearTimeout(this.state.logoutTimer);
      // show notification modal that user has been logged out
      this.setState({
        showSessionExpirationNotification: false,
        showLogoutNotification: true,
        logoutTimer: null,
        resetUserMemory: true,
      });
      // global sign out
      Auth.signOut();
    }, 1000 * 60 * this.state.sessionFinalNoticeDurationMins);

    this.setState({
      logoutTimer: logoutTimer,
    });
  };

  /**
   * Runs when user performs an action captured by idleTimer component
   * Logs user event to console
   * Logs time elapsed since idleTimer component first started
   * @param {object} event - mouse or keyboard action performed
   */
  handleOnAction(event) {
    ConsoleHelper('user did something', event);
    ConsoleHelper('time elapsed', this.idleTimer.getElapsedTime());
  }

  /**
   * Runs when user comes back from idle status
   * Logs user event that caused user to become active again
   * Logs time remaining in new timer created by idleTimer component
   * @param {object} event - mouse or keyboard action performed
   */
  handleOnActive(event) {
    ConsoleHelper('user is active', event);
    ConsoleHelper('time remaining', this.idleTimer.getRemainingTime());
  }

  render() {
    return (
      <div className='flex-shrink-0'>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * this.state.sessionTimeoutDurationMins}
          onIdle={this.promptAutoUserSignOut}
          // onActive={this.handleOnActive} // uncommment to test idleTimer
          // onAction={this.handleOnAction} // uncommment to test idleTimer
          debounce={500}
        />
        <DownloadUserGuide />
        <Information />
        <UploadFileForm
          authState={this.props.authState}
          resetUserMemory={this.state.resetUserMemory}
          submissionsEnded={this.props.submissionsEnded}
          submissionsStart={this.props.submissionsStart}
        />
        {
          // modals
        }
        <Modal
          show={this.state.showSessionExpirationNotification}
          animation
          backdrop='static'
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Session will expire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {`Session will expire due to inactivity in ${this.state.sessionFinalNoticeDurationMins} minute.`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={this.handleModalOnClick}
            >
              I'm here
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withAuthenticator(Main);
