import React, { Component } from 'react';
import './index.css';
// aws
import { Storage, Auth } from 'aws-amplify';
// bootstrap components
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
// combobox
import Select from 'react-select';
// import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
// dynamic file name update for file input
import bsCustomFileInput from 'bs-custom-file-input';
// react ui
import ExistingFileList from "../ExistingFileList";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
// import Alert from 'react-bootstrap/Alert';
import _ from 'lodash';
// import moment from 'moment-timezone';
import classNames from 'classnames'
import { Suspense } from 'react';
import ConsoleHelper from '../../helpers/ConsoleHelper';
// import axios from 'axios';
import config from '../../config/aws-settings';
import documentOptions from '../data/documentOptions.json';

// TODO - make sure the exhibit B validation end point.  It will be different than the AS exhibit B validation.

class UploadFileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isLoading: null,
      isUploading: false,
      proposalName: null,
      proposalOptions: [],
      usedId: [],
      user: null,
      show: false,
      fileList: [],
      fileUploadProgress: 60,
      showProgressBar: false,
      uploadedFiles: [],
      submittedFiles: [],
      proposalFiles: [],
      exhibitBFile: null,
      proposalSubmitted: null,
      uploadFolder: 'Upload',
      submitFolder: 'Submit',
      fileInputPlaceholder: 'Choose a file...',
      showUploadFileConfirmationModal: false,
      documentType: null,
      documentOptions: documentOptions,
      acceptedFileFormats: [
        '.xls',
        '.xlsx',
        '.pdf',
        '.doc',
        '.docx',
        '.jpeg',
        '.jpg',
        '.png',
      ],
      acceptedExhibitBFileFormats: [
        '.xlsx',
      ],
      requiredFolders: {
        error: 'Errored',
        upload: 'Upload',
        submit: 'Submit',
        validated: 'Validated',
      },
    };

    this.submitFormHandler = this.submitFormHandler.bind(this)
    this.readFileFromAWS = this.readFileFromAWS.bind(this)
    this.getMissingFileList = this.getMissingFileList.bind(this)
    // this.submissionPeriodEnd = this.submissionPeriodEnd.bind(this)
  }

  componentDidMount() {
    bsCustomFileInput.init(); // required for Proposal input combobox
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ user })
      });

    this.getAwsFileList();

    // this.getMissingFileList();

  }

  async readFileFromAWS(bucket, key) {
    Storage.configure({
      AWSS3: {
        bucket: bucket,
        region: 'us-west-2',
      },
      level: 'public',
    });

    console.log('read file from aws')

    return await Storage.get(key, { download: true })
  }

  componentDidUpdate(prevProps, prevState) {
    // only get new aws list if proposal input state is changed
    if (
      this.state.proposalName !== prevState.proposalName
    ) {
      ConsoleHelper('filepicker update file list')
      this.getAwsFileList();
    }
    // reset saved files if triggered from parent component
    if (prevProps.resetUserMemory !== this.props.resetUserMemory && this.props.resetUserMemory) {
      ConsoleHelper('filepicker reset state')
      this.setState({
        file: null,
        isUploading: false,
        proposalName: null,
        proposalOptions: [],
        usedId: [],
        fileList: [],
        uploadedFiles: [],
        submittedFiles: [],
        proposalFiles: [],
        exhibitBFile: null,
        proposalSubmitted: null,
        showUploadFileConfirmationModal: false,
        documentType: null,
        test: null,
      })
    }

    if (this.state.proposalName !== prevState.proposalName) {
      this.checkProposalSubmission();
      this.setState({ proposalFiles: null })
    }
  }

  /**
   * gets id numbers that have already been used from tracker file
   */
  // getUsedIdNumbers() {
  //   axios.get(`${process.env.PUBLIC_URL}/documents/randomNumbers.txt`)
  //     .then((res) => {
  //       console.log(res.data);
  //       this.setState({ usedId: res.data.split(',') })
  //       // let usedNumberList = res.data.split('\n').map((s) => parseInt(s))
  //       // this.setState({ usedId: usedNumberList })
  //     }).catch((err) => {
  //       console.log(err);
  //     })
  // }

  /**
 * Generates a new proposal ID that has not been used previously
 * @returns Number - ID number for new proposal
 */
  generateProposalIdNumber() {

    // let idNumber = GenerateNewId(this.state.usedId)

    // 4 digit random number generator
    let idNumber = Math.floor(1000 + Math.random() * 9000)
    ConsoleHelper(`${idNumber} generated`)

    this.setState({
      usedId: [...this.state.usedId, idNumber]
    })

    // TODO fix put issues after wildfire service is fully implemented
    // axios.put(`${process.env.PUBLIC_URL}/documents/randomNumbers.txt`, this.state.usedId.join(','))
    //   .then(function (response) {
    //     console.log('tracker file updated');
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    return idNumber;
  }

  /**
   * Check if the current proposal has been submitted
   */
  checkProposalSubmission() {
    const {
      proposalFiles,
      submittedFiles,
    } = this.state;
    // loop through current proposal files and check if it exists in the user's Submit folder
    let submittedProposalFiles =
      _.filter(proposalFiles, (curPropFile) => {
        let curPropFilename = curPropFile.props['file-name'];
        let foundSubmittedFile =
          _.find(submittedFiles, (subFile) => {
            let subFilename = subFile.props['file-name']
            return curPropFilename === subFilename
          })
        return foundSubmittedFile
      });

    if (submittedProposalFiles && proposalFiles) {
      // update state depending on results
      this.setState({
        proposalSubmitted: (submittedProposalFiles.length === proposalFiles.length) && (submittedProposalFiles.length !== 0 && proposalFiles.length !== 0) ? true : false
      })
    }
  }

  /**
   * Gets proposal-id from proposal's exhibit B file
   * @returns string - the current proposal's ID
   */
  getProposalId() {
    const { proposalFiles } = this.state;

    return proposalFiles[0] !== null && typeof proposalFiles[0] !== 'undefined' ? proposalFiles[0].props['proposal-id'] : null;
  }

  /**
 * creates a new option for the combobox input
 * @param {string} label - text to use for new option
 * @returns object to be used in the combobox dropdown
 */
  createOption = (label, id) => ({
    label,
    value: label,
    // idNumber: id ? id : Math.floor(1000 + Math.random() * 9000),
    idNumber: id ? id : this.generateProposalIdNumber(),
    // value: label.toLowerCase().replace(/\W/g, ''),
  });

  /**
   * creates the options for Proposal Name combobox.  If user has files uploaded into a proposal folder it will show up as an option.
   */
  createProposalOptions() {
    const { uploadedFiles } = this.state;
    let proposalOptions = [];
    ConsoleHelper('create options')

    this.setState({
      isLoading: true,
    })

    // pull proposal name from each file currently in user's bucket
    uploadedFiles.forEach((file, index) => {
      const {
        key,
      } = file;
      let fileInfo = key.split('/');

      // proposal naming convention
      // {{proposal name}}+{{id number}}

      let proposalData = fileInfo[1].split('+')
      let findMatch = _.find(proposalOptions, (option) => option.value === proposalData[0])
      // skip adding option if proposal label already exists
      if (findMatch) {
        return
      }
      let label = this.createOption(proposalData[0], proposalData[1]);
      proposalOptions.push(label);
    });

    this.setState({
      isLoading: false,
      // disableProposalInput: false,
      proposalOptions: _.orderBy(proposalOptions, ['value', 'label'], ['asc', 'asc']),  // sorts collection using lodash
    });
  }

  /**
   * Checks current state and returns disabled button if all fields are not filled out.  Returns enabled button if all fields are filled out.
   * @returns disabled or enabled button depending on state of form   
   */
  uploadButton() {
    let {
      file,
      proposalName,
      documentType,
      isUploading,
      exhibitBFile,
      proposalSubmitted,
    } = this.state;

    // check what state app is in to determine what 'Upload File' button to render
    if (proposalName
      && documentType
      && documentType.value === 'b'
      && file
      && exhibitBFile
      && !proposalSubmitted) {
      // if user is uploading exhibit b file to a proposal that already has an exhibit B
      return (<>
        <Button
          disabled={isUploading}
          variant="primary"
          type="submit"
          className="shadow"
          // size="lg"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showUploadFileConfirmationModal: true })
          }
          }
        >
          {isUploading ?
            <span>
              <Spinner
                className="mr-2"
                animation="grow"
                size="sm"
              />Uploading
            </span>
            : <span>
              <i
                className="bi bi-upload mr-2"
              >
              </i>Upload File
            </span>
          }
        </Button>
        <Modal
          centered
          animation
          show={this.state.showUploadFileConfirmationModal}
          onHide={() => this.setState({ showUploadFileConfirmationModal: false })}
          id="withdraw-modal"
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>
              Confirm
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            An Exhibit B already exists.  There can be only one (1) Exhibit B per proposal.  Uploading a new Exhibit B will replace current file.
            <br /><br />Do you want to continue?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="shadow"
              // size="lg"
              onClick={() => this.setState({ showUploadFileConfirmationModal: false })}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="shadow"
              // size="lg"
              onClick={(event) => {
                this.setState({ showUploadFileConfirmationModal: false });
                this.submitFormHandler(event);
              }}
            >
              Upload File
            </Button>
          </Modal.Footer>
        </Modal>
      </>)
    } else if (proposalName
      && documentType
      && file
      && !exhibitBFile
      && !proposalSubmitted) {
      // if file is currently being uploaded
      return (
        <Button
          variant="primary"
          type="submit"
          className="shadow"
          disabled={isUploading}
        // size="lg"
        >
          {isUploading
            ?
            <span>
              <Spinner
                className="mr-2"
                animation="grow"
                size="sm"
              />
              Uploading
            </span>
            :
            <span>
              <i
                className="bi bi-upload mr-2 shadow"
              ></i>
              Upload File
            </span>
          }
        </Button>
      )
    } else if (proposalName
      && documentType
      && file
      && (!proposalSubmitted || proposalSubmitted === null)) {
      // if proposal, document type, and file have been filled out
      return <Button
        disabled={isUploading}
        variant="primary"
        type="submit"
      // size="lg"
      >
        {isUploading
          ?
          <span>
            <Spinner
              className="mr-2"
              animation="grow"
              size="sm"
            />
            Uploading
          </span>
          :
          <span>
            <i
              className="bi bi-upload mr-2 shadow"
            ></i>
            Upload File
          </span>
        }
      </Button>
    }

    // as default action, return a disabled button
    return (
      <Button
        disabled
        variant="primary"
        type="submit"
        className="shadow"
      // size="lg"
      ><i
        className="bi bi-upload mr-2 shadow"
      ></i>
        Upload File
      </Button>
    )
  }

  /**
   * Callback function to send to children components to change state of this component
   * @param {object} obj - object representing the state to change in the parent component
   */
  setParentState = (obj) => {
    this.setState(obj)
  }

  getPublicList = () => {
    Storage.configure({
      AWSS3: {
        bucket: config.buckets.private,
        region: 'us-west-2',
      },
      level: 'public',
    });

    return Storage.list('')
  }

  getMissingFileList() {
    ConsoleHelper('upload file form:: get aws file list')
    
    Storage.configure({
      AWSS3: {
        bucket: config.buckets.wildfireOutput,
        region: 'us-west-2',
      },
      level: 'public',
    });

    Storage.list('')
    .then((result) =>{
      console.log('public folder in wildfire output bucket')
      console.log(result)
    })

  }

  /**
   * Gets user's file list from AWS, then creates Proposal Name options for Proposal Name combobox
   * file name template
   * grfp_{PROPOSAL_ID}`{PROPOSAL_NAME}`{USERNAME}`{DOC_TYPE}`{FILE_NAME}
   */
  getAwsFileList() {
    ConsoleHelper('upload file form:: get aws file list')
    
    Storage.configure({
      AWSS3: {
        bucket: config.buckets.private,
        region: 'us-west-2',
      },
      level: 'private',
    });

    Storage.list('')
      .then((result) => {
        // loop through complete file list and attach props object with values
        let fileList = _.map(result, (file) => {
          let { key } = file,
            fileProps = key
              .substring(key.lastIndexOf('/') + 1)
              .split('`');

          // attach file props extracted from naming convention
          file.props = {
            'project-id': null,
            'proposal-id': null,
            'proposal-name': null,
            'username': null,
            'document-type-shorthand': null,
            'document-type': null,
            'original-file-name': null,
            'file-name': null,
          }

          // skip files that are either root folders
          // or lambda trigger files in Submit
          if (key.split('/').length <= 2) {
            return file
          }

          // set values for props
          // set root project id 'grfp'
          file.props['project-id'] = fileProps[0]
          // set proposal id
          file.props['proposal-id'] = fileProps[1]
          // set proposal name
          file.props['proposal-name'] = fileProps[2]
          // set username
          file.props['username'] = fileProps[3]
          // set doc type
          file.props['document-type-shorthand'] = fileProps[4]
          file.props['document-type'] =
            fileProps[4]
              ? _.find(this.state.documentOptions, ['value', fileProps[4]]).label
              : null
          // set original file name
          file.props['original-file-name'] = fileProps[5]
          // set file name
          file.props['file-name'] =
            key.substring(key.lastIndexOf('/') + 1)

          return file
        })

        // create list for folders only in UPLOAD folder
        let uploadedFiles =
          // _.filter(fileList, (file) => file.key.split('/')[0] === this.state.uploadFolder && !file.key.endsWith('/'));
          _.filter(fileList, (file) => file.key.split('/')[0] === this.state.uploadFolder && !file.key.endsWith(`${this.state.requiredFolders.upload}/`));

        // create list for folders only in SUBMIT folder
        let submittedFiles =
          _.filter(fileList, (file) => file.key.split('/')[0] === this.state.submitFolder && !file.key.endsWith('/') && file.key.split('/').length > 2);

        // let proposalList = _.filter(fileList, (file) => file.key.endsWith('/') && !file.key.endsWith('Upload/') && !file.key.endsWith('Submit/'))
        // apply additional filters if proposal name has been selected
        let proposalFiles = [];
        let exhibitBFile = [];
        if (this.state.proposalName) {
          // store file related to current proposal
          proposalFiles = _.filter(uploadedFiles, (file) => {
            return file.props['proposal-name'] === this.state.proposalName.label
          })
          // store exhibit b file for current proposal
          exhibitBFile =
            _.filter(proposalFiles, (file) => {
              return file.props['document-type-shorthand'] === 'b'
            })
        }

        ConsoleHelper('user files')
        ConsoleHelper('fileList')
        ConsoleHelper(fileList)
        ConsoleHelper('uploadedFiles')
        ConsoleHelper(uploadedFiles)
        ConsoleHelper('submittedFiles')
        ConsoleHelper(submittedFiles)
        ConsoleHelper('proposalFiles')
        ConsoleHelper(proposalFiles)
        ConsoleHelper('exhibitBFile')
        ConsoleHelper(exhibitBFile[0])
        ConsoleHelper('user files end')

        this.setState({
          uploadedFiles,
          submittedFiles,
          proposalFiles,
          fileList,
          exhibitBFile: exhibitBFile[0],
        })
      })
      .then(() => {
        this.createProposalOptions();
        this.checkProposalSubmission();
      })
      .catch(err => ConsoleHelper(err));
  }

  /**
   * validate the proposal name
   */
  validProposalName() {
    const { proposalName } = this.state
    const proposalRegex = new RegExp("^[a-zA-Z0-9- _]+$")

    // front end validation
    // check if file name is less than 10 characters
    if (proposalName.label.length > 20) {
      alert("Proposal Name too long.  Up to 20 characters are allowed.");
      this.setState({ isUploading: false });
      return false;
    }
    // check if proposalID is blank
    // *scenario should happen.  Upload button will not enable until all inputs have data
    else if (this.state.proposalName === null) {
      alert("Please enter an Proposal ID.");
      this.setState({ isUploading: false });
      return false;
    }
    // check if proposal name contains unacceptable characters
    else if (!proposalRegex.test(this.state.proposalName.label)) {
      alert("Error: Proposal Name can only include letters, numbers, -, or _");
      this.setState({ isUploading: false });
      return false;
    }

    return true;
  }

  /**
   * Validate properties of the file being uploaded
   */
  validFile() {
    const {
      file,
      acceptedFileFormats,
      acceptedExhibitBFileFormats,
      documentType,
    } = this.state
    const proposalRegex = new RegExp("^[a-zA-Z0-9- _]+$")
    let fileName = file.name
    let fsize = file.size
    let bytesInMb = 1048576;
    ConsoleHelper(fsize / bytesInMb)

    // front end validation
    if (file === '' || fileName === '') {
      alert("Please enter a file to upload.");
      this.setState({ isUploading: false });
      return false
    }
    // check if invalid file type for Exhibit B doc type
    else if (documentType.value === 'b' && !acceptedExhibitBFileFormats.includes(fileName.toLowerCase().substring(fileName.lastIndexOf('.')))) {
      alert(`File Type is not allowed for an Exhibit B. Allowed file type(s) are: ${acceptedExhibitBFileFormats.join(' ')}`);
      this.setState({ isUploading: false });
      return false
    }
    // check if invalid file type for other doc types
    else if (documentType.value !== 'b' && !acceptedFileFormats.includes(fileName.toLowerCase().substring(fileName.lastIndexOf('.')))) {
      alert(`File Type is not allowed.  Allowed file type(s) are: ${acceptedFileFormats.join(' ')}`);
      this.setState({ isUploading: false });
      return false
    }
    // check if file name is less than 50 characters
    else if (fileName.lastIndexOf('.') > 50) {
      alert("File name too long.  Up to 50 characters are allowed.");
      this.setState({ isUploading: false });
      return false
    }
    // check if file exceeds size limit
    else if ((fsize / bytesInMb) >= 25) {
      alert("File too Big, please select a file less than 25 MB");
      this.setState({ isUploading: false });
      return false
    }
    // check if file name contains unacceptable characters
    else if (!proposalRegex
      .test(fileName.substring(0, fileName.lastIndexOf('.')))) {
      alert("File Name can only include letters, numbers, - (dash), or _ (underscore)");
      this.setState({ isUploading: false });
      return false
    }

    return true
  }

  /**
   * Validate other user limitations that are in place
   * - max limit on number of proposals that a user can have
   * - max limit on total number of files a user can upload to AWS
   */
  validUserLimits() {
    const { fileList } = this.state
    let proposals = []
    let fileCount = 0

    // track number of proposals submitted
    fileList.forEach(item => {
      // skip folder items
      if (item.key.endsWith('/')) {
        return
      }
      fileCount++
      if (!proposals.includes(item.props['proposal-name'])) {
        proposals.push(item.props['proposal-name'])
      }
    })

    // check if user has reached limit of file uploads
    if (fileCount > 100) {
      alert("Maximum files uploaded as a user (100). Please contact support for permission to add more.");
      this.setState({ isUploading: false });
      return false
    }
    // check number of files uploaded
    else if (proposals.length > 10 && !proposals.includes(this.state.proposalName.label)) {
      alert("Maximum proposals reached (10).  Please contact support for permission to add more.");
      this.setState({ isUploading: false });
      return false
    }

    return true
  }

  /**
   * Upload files to users private folder
   * @returns whether the upload was successful
   */
  uploadToPrivateBucket() {
    return new Promise((resolve, reject) => {
      let {
        file,
        proposalName,
      } = this.state;
      let fileName = file.name;
      let curYear = new Date().getFullYear();

      Auth.currentUserInfo()
        .then(userInfo => {
          // declare value for custom file name to use to upload to AWS.
          const customFileNameValues = [
            'grfp',
            `t-der-rfp${curYear}-${proposalName.idNumber}`,
            this.state.proposalName.value,
            userInfo.username,
            this.state.documentType.value,
            fileName,
          ];

          try {
            Storage
              .configure({
                AWSS3: {
                  bucket: config.buckets.private,
                  region: 'us-west-2',
                },
                level: 'private',
              })
            Storage
              .put(
                // uncomment for converting to back tick in naming convention
                // `Upload/${proposalName.label.trim()}-${proposalName.idNumber}/${customFileNameValues.join('`')}`
                `Upload/${proposalName.label.trim()}+${proposalName.idNumber}/${customFileNameValues.join('`')}`
                , file, {
                level: 'private',
                progressCallback(progress) {
                  ConsoleHelper('private bucket upload')
                  ConsoleHelper(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
                contentType: 'video/*',
              })
              .then((result) => {
                ConsoleHelper('private bucket upload file completed')
                ConsoleHelper(result)
                resolve('user file uploaded')
              }).catch((e) => {
                ConsoleHelper('error')
                ConsoleHelper(e)
              })
          } catch (e) {
            alert("There was an error uploading your file.  Please contact the administrator.");
            ConsoleHelper('error uploading file: ', e)
          }
        })
    })
  }

  /**
   * performs some file validation and uploads to AWS bucket.
   * @param {object} event the form submit event object
   */
  async submitFormHandler(event) {
    ConsoleHelper('submit form')

    this.setState({
      isUploading: true,
    });

    const {
      exhibitBFile,
      documentType,
    } = this.state;

    event.preventDefault();

    // only run if all the form validations passed
    if (this.validFile()
      // && this.validUserLimits()
      && this.validProposalName()) {

      if (exhibitBFile && documentType.value === 'b') {
        // an exhibit B file already exists
        // delete old exhibit b and upload new exhibit to private bucket
        Promise.allSettled([
          this.deleteFileFromPrivateBucket(exhibitBFile.key),
          this.uploadToPrivateBucket(),
        ]).then((values) => {
          setTimeout(() => {
            ConsoleHelper('file uploaded')
            ConsoleHelper(values)
            this.resetFileInput();
            this.getAwsFileList();
            this.setState({
              isUploading: false,
              documentType: null,
              file: null,
            });
          }, 3000)
        })

      } else {
        // if exhibit b doesn't exist
        // upload exhibit b to private bucket
        Promise.allSettled([
          this.uploadToPrivateBucket(),
        ]).then((values) => {
          setTimeout(() => {
            ConsoleHelper('file uploaded')
            ConsoleHelper(values)
            this.resetFileInput();
            this.getAwsFileList();
            this.setState({
              isUploading: false,
              documentType: null,
              file: null,
            });
          }, 3000)
        })
      }
    }
  }

  /**
   * Deletes provided key from user's private bucket
   * @param {string} key - key to delete from aws
   * @returns Promise function to delete key from user's private bucket
   */
  deleteFileFromPrivateBucket(key) {

    if (key !== null && key !== '' && typeof key !== 'undefined') {
      return new Promise((resolve, reject) => {
        // point to bucket to download file from
        Storage.configure({
          AWSS3: {
            bucket: config.buckets.private,
            region: 'us-west-2',
          },
          level: 'private',
        });

        Storage.remove(key)
          .then((result) => {
            // ConsoleHelper(Storage)
            // ConsoleHelper(result.$metadata.httpStatusCode)
            if (result.$metadata.httpStatusCode === 204) {
              resolve(`${key} deleted`)
            }
          });
      })
    }
    return Promise.resolve('key empty')
  }

  /**
   * Reset the file input element
   */
  resetFileInput() {
    document.getElementById('file-upload').value = '';
    document.getElementById('file-upload')
      .closest('div')
      .querySelector('label').innerText = this.state.fileInputPlaceholder;
  }

  /**
   * Sets the new option selected into state
   * @param {string} newValue - the new option selected from dropdown
   * @param {object} actionMeta - event action object for component
   */
  handleProposalChange = (newValue, actionMeta) => {
    ConsoleHelper('Proposal Value Changed');
    ConsoleHelper(newValue);
    ConsoleHelper(`action: ${actionMeta.action}`);

    

let myData = this.readFileFromAWS(config.buckets.wildfireOutput, `Missing/4233.json`);

myData.then((result) => {
  console.log('result')
  console.log(result)
})

    this.setState({ proposalName: newValue });
  };

  /**
   * Set the new documentype to state
   * @param {string} newValue - the new options selected from dropdown
   * @param {object} actionMeta - event action of component
   */
  handleDocumentChange = (newValue, actionMeta) => {
    ConsoleHelper('Document Type Value Changed');
    ConsoleHelper(newValue);
    ConsoleHelper(`action: ${actionMeta.action}`);
    this.setState({ documentType: newValue });
  };

  /**
   * Sets the uploaded files to state
   * @param {object} e - event object that contains click dom event
   */
  handleFileChange(e) {
    ConsoleHelper('File Changed');
    ConsoleHelper(e.target.files[0]);
    this.setState({ file: e.target.files[0] });
  }

  /**
   * creates a new option for the combobox element
   * @param {string} inputValue - Custom option to add to combobox
   */
  handleCreate = (inputValue) => {
    ConsoleHelper('handle create');
    this.setState({
      isLoading: true,
    });
    ConsoleHelper('Wait a moment...');

    setTimeout(() => {
      const { proposalOptions } = this.state;
      const newOption = this.createOption(
        inputValue
          .trim()
          .toLowerCase()
      );
      ConsoleHelper(newOption);
      this.setState({
        isLoading: false,
        proposalOptions: [...proposalOptions, newOption],
        proposalName: newOption,
      });

    }, 2000);
  };

  render() {
    const {
      proposalName,
      proposalOptions,
      documentType,
      documentOptions,
      isLoading,
      proposalFiles,
    } = this.state;

    return (
      <div
        className="filepicker-section text-left"
      >
        <Container
          className="filepicker section rounded-lg"
        >
          <Container>
            <Form
              onSubmit={this.submitFormHandler}
            >
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="referenceDocUpload"
                >
                  <Form.Label
                    className="mb-3"
                  >
                    <span
                      className="fa-layers fa-fw mr-3"
                    >
                      <i
                        className="fas fa-circle fa-2x"
                      ></i>
                      <span
                        className="fa-layers-text text-white icon-text"
                      >1
                      </span>
                    </span>
                    Proposal Name
                  </Form.Label>
                  <Creatable
                    autoFocus
                    placeholder="Create a new proposal..."
                    noOptionsMessage={() => "No proposals found"}
                    id='proposalID'
                    isClearable
                    isDisabled={this.state.isUploading || this.state.isLoading}
                    isLoading={isLoading}
                    onChange={this.handleProposalChange}
                    formatCreateLabel={(curVal) => `Click to create '${curVal}' proposal`}
                    onCreateOption={this.handleCreate}
                    options={proposalOptions}
                    value={proposalName}
                  />
                  <Form.Text
                    // muted
                    id="proposalNameHelp"
                  >Allowed characters:  A-Z,  a-z,  0-9,  - (dash) _ (underscore)
                    <br />Character Limit: 20 Characters.</Form.Text>
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="referenceDocUpload"
                  className={classNames({
                    'd-none': this.props.submissionsEnded || this.props.submissionsStart
                  })}
                >
                  <Form.Label
                    className="mb-3"
                  >
                    <span
                      className="fa-layers fa-fw mr-3"
                    >
                      <i
                        className="fas fa-circle fa-2x"
                      ></i>
                      <span
                        className="fa-layers-text text-white icon-text"
                      >2
                      </span>
                    </span>
                    Document Type
                  </Form.Label>
                  <Select
                    placeholder="Select document type..."
                    isSearchable={false}
                    options={documentOptions}
                    isDisabled={this.state.isUploading || this.state.proposalSubmitted}
                    onChange={this.handleDocumentChange}
                    id="documentType"
                    // autoFocus
                    value={documentType}
                    isClearable
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className={classNames({
                    'd-none': this.props.submissionsEnded || this.props.submissionsStart
                  })}
                >
                  <Form.Label
                    className="mb-3"
                  >
                    <span
                      className="fa-layers fa-fw mr-3"
                    >
                      <i
                        className="fas fa-circle fa-2x"></i>
                      <span
                        className="fa-layers-text text-white icon-text"
                      >3
                      </span>
                    </span>
                    File
                  </Form.Label>
                  <Form.File
                    id="file-upload"
                    label={this.state.fileInputPlaceholder}
                    onChange={this.handleFileChange.bind(this)}
                    disabled={this.state.isUploading || this.state.proposalSubmitted}
                    accept={this.state.acceptedFileFormats.join(',')}
                    custom
                    aria-describedby="fileChooseHelp"
                  />
                  <Form.Text
                    id="fileChooseHelp"
                  >
                    Allowed formats: {this.state.acceptedFileFormats.join(', ')}
                    <br />Character Limit: 50 Characters.
                    <br />Max File Size: 25 MB</Form.Text>
                </Form.Group>
              </Form.Row>
              <Form.Row
                className={classNames({
                  'd-none': this.props.submissionsEnded || this.props.submissionsStart,
                  'no-gutters': true,
                  'd-flex': !this.props.submissionsEnded || !this.props.submissionsStart,
                  'justify-content-end': true,
                })}
              >
                {this.uploadButton()}
              </Form.Row>
            </Form>
          </Container>
        </Container>

        <Suspense
          fallback={<h1>Loading files...</h1>}>
          {/* Only show file list if fileList has values */
            proposalFiles && proposalFiles.length !== 0 && proposalName &&
            <ExistingFileList
              proposalName={this.state.proposalName}
              documentOptions={this.state.documentOptions}
              proposalFiles={this.state.proposalFiles}
              fileList={this.state.fileList}
              refreshFileListCallback={() => this.getAwsFileList()}
              setParentState={this.setParentState}
              user={this.state.user}
              proposalSubmitted={this.state.proposalSubmitted}
              proposalId={this.getProposalId()}
              submissionEnded={this.props.submissionsEnded}
              submissionsStart={this.props.submissionsStart}
            />
          }
        </Suspense>
        {
          // validation error modal
        }
        <Modal
          show={this.state.showFormValidationErrorModal}
          onHide={() => { this.setState({ showFormValidationErrorModal: false }) }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Form Validation Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.formValidationError}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => { this.setState({ showFormValidationErrorModal: false }) }}
            >Understood</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UploadFileForm;
