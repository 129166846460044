import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { AuthState } from "@aws-amplify/ui-components";
// styles
import './index.css';
// components
import Container from 'react-bootstrap/Container';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <header
        className={this.state.authState === AuthState.SignedIn ? 'container-fluid pse-bg' : 'container-fluid pse-bg mb-auto'}
      >
        <Container
          className="d-flex justify-content-between"
        >
          <div>
            <a href="https://www.pse.com/">
              <img
                src={require("../../assets/images/pse-logo-secondary.png")}
                alt="Puget Sound Energy Logo"
              />
            </a>
          </div>
          {// only show sign out button if user is signed in
            this.props.authState === AuthState.SignedIn && <div
              className="d-flex justify-content-end"
            >
              <div
                className="signoutdiv"
              >
                <AmplifySignOut
                  buttonText="Sign Out"
                  width="50px"
                >
                </AmplifySignOut>
              </div>
            </div>
          }
        </Container>
      </header>
    );
  }
}

Header.propTypes = {
  authData: PropTypes.string,
};

export default Header;
