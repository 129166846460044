import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
// component styles
import './index.css';
// custom components
import Validating from '../../components/Validating';
import FileRowActions from '../../components/FileRowActions';
import ExistingFileListHeader from '../../sections/ExistingFileListHeader';
import SubmissionInformation from '../../sections/SubmissionInformation';
// bootstrap components
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import * as helpers from './functions';
import classNames from 'classnames';
import ProposalSubmissionStatus from '../ProposalSubmissionStatus';
import ConsoleHelper from '../../helpers/ConsoleHelper';
import config from '../../config/aws-settings';

// import downloadBlob from './functions';

class ExistingFileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmitModal: false,
      submittingProposal: false,
      showSubmitProposalError: false,
      showProposalSubmittedConfirmation: false,
      showDeleteModal: false,
      showWithdrawModal: false,
      enableWithdrawProposal: null,
      tableHeaders: [
        'Proposal Name',
        'Document Type',
        'File Name',
        'Uploaded Date',
        'Validation Status',
        'Actions',
      ],
      proposalFiles: [],
      validatedFiles: [],
      erroredFiles: [],
      submitFolder: 'Submit',
      uploadFolder: 'Upload',
      wildfireFolder: 'generationRFP',
      validatedFolder: 'Validated',
      errorFolder: 'Errored',
    };

    this.checkForValidationFile = this.checkForValidationFile.bind(this);
    this.checkValidationStatus = this.checkValidationStatus.bind(this);
    this.handleSubmitProposal = this.handleSubmitProposal.bind(this);
    this.repeatCheckValidationStatus =
      this.repeatCheckValidationStatus.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
    this.setWithdrawProposalButtonState =
      this.setWithdrawProposalButtonState.bind(this);
    this.createMainTable = this.createMainTable.bind(this);
    this.handleWithdrawProposal = this.handleWithdrawProposal.bind(this);
    this.createTableRow = this.createTableRow.bind(this);
    this.showWithdrawProposalButton =
      this.showWithdrawProposalButton.bind(this);
    this.displayExhibitFileWarningMessage =
      this.displayExhibitFileWarningMessage.bind(this);
  }

  componentDidMount() {
    this.setState({ proposalFiles: this.props.proposalFiles });
    this.checkValidationStatus();
    this.repeatCheckValidationStatus();
    this.enableSubmitButton();

    ConsoleHelper('existing file list mounted');
    ConsoleHelper(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // check if the file list passed in from parent is different than current
    let different = _.difference(
      prevProps.proposalFiles,
      this.props.proposalFiles
    );

    if (!this.props.submissionEnded) {
      if (different.length > 0) {
        this.checkValidationStatus();
        this.repeatCheckValidationStatus();
        this.setState({
          proposalFiles: this.props.proposalFiles,
        });
      }

      this.enableSubmitButton();
      this.setWithdrawProposalButtonState();
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  // usage
  // async download() {
  //   const result = await Storage.get(fileKey, { download: true });
  //   downloadBlob(result.Body, 'filename');
  // }

  /**
   * Checks aws buckets in set interval to get current status of uploaded files
   */
  repeatCheckValidationStatus() {
    this.myInterval = setInterval(() => {
      // let pendingFiles = _.filter(this.props.proposalFiles, {
      //   validated: false,
      // });

      this.checkValidationStatus();

      // REMOVING STOP INTERVAL LOOP LOGIC

      // clear interval if all files have been validated
      // if (pendingFiles.length === 0) {
      //   ConsoleHelper("interval cleared");
      //   clearInterval(this.myInterval);
      // }
    }, 10000);
  }

  enableSubmitButton() {
    const { proposalSubmitted } = this.props;

    let enable =
      helpers.checkUploadedFilesValid(this.props.proposalFiles) &&
      (proposalSubmitted === null || !proposalSubmitted)
        ? true
        : false;

    return enable;
  }

  /**
   * Check conditions of application to determine if Withdraw Proposal button should be enabled
   * Condition(s):
   * - Only if a proposal is submitted
   */
  setWithdrawProposalButtonState() {
    const { fileList, proposalName, proposalFiles } = this.props;
    const { enableWithdrawProposal } = this.state;

    // filter fileList to only current proposal in the 'Submit' folder
    let submittedProposalFiles = _.filter(fileList, (file) => {
      let { key } = file;
      return key.startsWith(
        `Submit/${proposalName.label}-${proposalName.idNumber}/`
      );
    });

    // check if all the current proposal files have been submitted
    let matchedFiles = _.filter(proposalFiles, (projFile) => {
      let fileName = projFile.props['file-name'];
      // loop through submittedProposalFiles list till we find a file with the same fileName
      let foundFile = _.find(submittedProposalFiles, (submitFile) => {
        let { key } = submitFile;
        return key.endsWith(fileName);
      });
      // returns true or false if a files was found to determine whether to keep ignore or save current proposal file
      return foundFile ? true : false;
    });

    // check if matched file match the save length as the current proposal files
    let futureState = matchedFiles.length === proposalFiles.length;

    // Only update state, if all files have been submitted and current state is different from
    if (
      matchedFiles.length === proposalFiles.length &&
      enableWithdrawProposal !== futureState
    ) {
      // change component state
      this.setState({
        enableWithdrawProposal: futureState,
      });
      // change parent component state
      this.props.setParentState({ proposalSubmitted: futureState });
    }
  }

  /**
   * Store AWS Error files results to application state
   */
  saveErroredFilesList(result) {
    // create local array to store previously retrieved error files
    let erroredFiles = this.state.erroredFiles;
    ConsoleHelper(`erroredFiles length:  ${erroredFiles.length}`);
    ConsoleHelper('data retrieved from aws');
    ConsoleHelper(result);

    // loop through results to check if KEY exists in erroredFiles array
    result.forEach((file) => {
      let matchFound = erroredFiles.filter((savedFile) => {
        return file.key === savedFile.key;
      });

      if (matchFound.length === 0) {
        erroredFiles.push(file);
      }
    });

    // set proposalFiles to state
    this.setState({
      erroredFiles,
    });
  }

  /**
   * Store AWS Error files results to application state
   */
  saveValidatedFilesList(result) {
    // create local array to store previously retrieved error files
    let validatedFiles = this.state.validatedFiles;
    ConsoleHelper(`validatedFiles length:  ${validatedFiles.length}`);
    ConsoleHelper('data retrieved from aws');
    ConsoleHelper(result);

    // loop through results to check if KEY exists in erroredFiles array
    result.forEach((file) => {
      let matchFound = validatedFiles.filter((savedFile) => {
        return file.key === savedFile.key;
      });

      if (matchFound.length === 0) {
        validatedFiles.push(file);
      }
    });

    // set proposalFiles to state
    this.setState({
      validatedFiles,
    });
  }

  /**
   * Check AWS buckets for file validation results
   */
  checkForValidationFile_new() {
    const { erroredFiles, validatedFiles } = this.state;

    ConsoleHelper('validation new function');

    // check if proposal files have been validated or errored
    let proposalFiles = this.props.proposalFiles;
    proposalFiles = _.forEach(proposalFiles, (upFile) => {
      let fileName = upFile.props['file-name'].substring(
        0,
        upFile.props['file-name'].lastIndexOf('.')
      );

      // check if found a match in validated folder
      let validatedMatch = _.find(validatedFiles, (obj) => {
        let { key } = obj;
        return key.endsWith(`${fileName}.txt`);
      });

      // check if found a match in error files folder
      let errorMatch = _.find(erroredFiles, (obj) => {
        let { key } = obj;
        return key.endsWith(`${fileName}.txt`);
      });
      // add validated attribute to file obj for easy tracking
      upFile.validated = validatedMatch ? true : false;
      upFile.validatedFile = validatedMatch ? validatedMatch.key : '';
      // add error attribute to file obj for easy tracking
      upFile.error = errorMatch ? true : false;
      upFile.errorFile = errorMatch ? errorMatch.key : '';
    });

    // set proposalFiles to state
    this.setState({
      proposalFiles,
    });
  }

  /**
   * Check AWS buckets for file validation results
   */
  checkForValidationFile(result) {
    const { errorFolder, validatedFolder } = this.state;

    ConsoleHelper('data retrieved from aws');
    ConsoleHelper(result);
    // filter result to only VALIDATED folder
    let validatedFiles = _.filter(result, (file) => {
      return file.key.indexOf(validatedFolder) > -1 && !file.key.endsWith('/');
    });

    // filter result to only files in the ERROR folder
    let errorFiles = _.filter(result, (file) => {
      return file.key.indexOf(errorFolder) > -1 && !file.key.endsWith('/');
    });

    // check if proposal files have been validated or errored
    let proposalFiles = this.props.proposalFiles;
    proposalFiles = _.forEach(proposalFiles, (upFile) => {
      let fileName = upFile.props['file-name'].substring(
        0,
        upFile.props['file-name'].lastIndexOf('.')
      );

      // check if found a match in validated folder
      let validatedMatch = _.find(validatedFiles, (obj) => {
        let { key } = obj;
        return key.endsWith(`${fileName}.txt`);
      });

      // check if found a match in error files folder
      let errorMatch = _.find(errorFiles, (obj) => {
        let { key } = obj;
        return key.endsWith(`${fileName}.txt`);
      });
      // add validated attribute to file obj for easy tracking
      upFile.validated = validatedMatch ? true : false;
      upFile.validatedFile = validatedMatch ? validatedMatch.key : '';
      // add error attribute to file obj for easy tracking
      upFile.error = errorMatch ? true : false;
      upFile.errorFile = errorMatch ? errorMatch.key : '';
    });

    // set proposalFiles to state
    this.setState({
      proposalFiles,
    });
  }

  /**
   * Get status of file validation by checking the designated bucket that the validated files are moved to once validated
   */
  checkValidationStatus() {
    Storage.configure({
      AWSS3: {
        bucket: config.buckets.wildfireOutput,
        region: 'us-west-2',
      },
      level: 'public',
    });

    // get files from all folders in public folder in Wildfire output bucket
    Storage.list('Validated/')
      .then((result) => {
        ConsoleHelper('validated');
        this.saveValidatedFilesList(result);
      })
      .then(() => {
        Storage.list('Validated_Old2/').then((result) => {
          ConsoleHelper('Validated_Old2');
          this.saveValidatedFilesList(result);
        });
      })
      .then(() => {
        Storage.list('Validated_Old1/').then((result) => {
          ConsoleHelper('Validated_Old1');
          this.saveValidatedFilesList(result);
        });
      })
      .then(() => {
        Storage.list('Validated_Old/').then((result) => {
          ConsoleHelper('Validated_Old');
          this.saveValidatedFilesList(result);
        });
      })
      .then(() => {
        Storage.list('Errored/').then((result) => {
          ConsoleHelper('Errored');
          this.saveErroredFilesList(result);
        });
      })
      .then(() => {
        this.checkForValidationFile_new();
      });
  }

  /**
   * Creates the table elements for the current proposal files
   * @returns Object array of table rows for the current proposal files.
   */
  createMainTable() {
    const { proposalFiles } = this.state;
    const { documentOptions } = this.props;

    if (proposalFiles === null || proposalFiles.length === 0) {
      return (
        <tr>
          <td colSpan='6'>No proposal files to list</td>
        </tr>
      );
    }

    let myProposalFiles = proposalFiles.map((file) => {
      let { key, lastModified, validated, validatedFile, error, errorFile } =
        file;
      let fileProps = key.substring(key.lastIndexOf('/') + 1).split('`');

      // create data-friendly object to pass into createTableRow
      // for docType, set value to Option object from dropdown input
      // file name template
      // grfp_{{PROJECT_ID}}`{{PROPOSAL_NAME}}`{{USERNAME}}`{{DOC_TYPE}}`{{FILE_NAME}}
      return {
        proposalName: fileProps[2],
        documentType: _.find(documentOptions, ['value', fileProps[4]]).label,
        documentTypeShorthand: _.find(documentOptions, ['value', fileProps[4]])
          .value,
        fileName: fileProps[5],
        uploadDate: lastModified.toString(),
        key,
        validated,
        validatedFile,
        error,
        errorFile,
      };
    });

    // Sort data files by document type then filename
    myProposalFiles = _.sortBy(
      myProposalFiles,
      (obj) => obj.documentType.label
    );

    // loop through collection and create html code
    return myProposalFiles.map((fileData, index) => {
      return this.createTableRow(fileData, index);
    });
  }

  /**
   * withdraw proposal
   */
  handleWithdrawProposal = () => {
    const { fileList, proposalId, proposalName } = this.props;
    // point to bucket to delete file from
    Storage.configure({
      AWSS3: {
        bucket: config.buckets.private,
        region: 'us-west-2',
      },
      level: 'private',
    });

    // check if files exist in Upload user's private folder
    // begin filtering of aws files to only files in the SUBMIT folder and this proposal folder
    let relatedFiles = fileList;
    // filter to only files in SUBMIT folder
    relatedFiles = _.filter(relatedFiles, (file) => {
      const { key } = file;
      return key.indexOf(this.state.submitFolder) > -1;
    });

    // filter to only proposal files
    relatedFiles = _.filter(relatedFiles, (file) => {
      const { key } = file;
      const { label, idNumber } = proposalName;
      let findMe = `${this.state.submitFolder}/${label}+${idNumber}/`;
      return key.startsWith(findMe);
    });

    // sort files to put root proposal folder at bottom of collection
    relatedFiles = _.orderBy(relatedFiles, ['key'], ['desc']);

    // delete proposal in SUBMIT folder
    ConsoleHelper(`deleting files from private Submit folder`);

    relatedFiles.forEach((file) => {
      let { key } = file;
      Storage.remove(key).then((result) => {
        this.props.refreshFileListCallback();
      });
    });

    // file name template
    // rfp_withdrawn_{{PROPOSAL-ID}}.txt
    Storage.put(
      `${this.state.submitFolder}/rfp\`withdrawn\`${proposalId}.txt`,
      {
        level: 'private',
      }
    ).then(() => {
      this.setWithdrawProposalButtonState();
    });

    this.setState({
      showWithdrawModal: false,
    });
    // change parent component state
    this.props.setParentState({ proposalSubmitted: false });
  };

  /**
   * submit proposal
   * will create a blank .txt file in Submit folder to trigger lambda function to copy folder/files in user's 'Upload' folder into the 'Submit' folder
   */
  handleSubmitProposal = (e) => {
    const { proposalName } = this.props;

    this.setState({
      submittingProposal: true,
    });

    // point to bucket to private bucket
    Storage.configure({
      AWSS3: {
        bucket: config.buckets.private,
        region: 'us-west-2',
      },
      level: 'private',
    });

    // file name template for file to trigger lambda
    // rfp_submitted_{{PROJECT-NAME}}
    Storage.put(
      `${this.state.submitFolder}/rfp\`submitted\`${proposalName.label}+${proposalName.idNumber}.txt`,
      {
        level: 'private',
      }
    )
      .then(() => {
        setTimeout(() => {
          this.setState({
            showSubmitModal: false,
            submittingProposal: false,
            showProposalSubmittedConfirmation: true,
          });
          this.props.setParentState({ proposalSubmitted: true });
          this.props.refreshFileListCallback();
        }, 5000);
      })
      .catch((e) => {
        ConsoleHelper('submit proposal error');
        ConsoleHelper(e);
        this.setState({
          showSubmitProposalError: true,
          showSubmitModal: false,
          submittingProposal: false,
          showProposalSubmittedConfirmation: false,
        });
      });
  };

  /**
   * Generates a table row with provided file object.
   * @param {object} file - file properties
   * @param {integer} index - key value to add to react elements
   * @returns table row with columns containing file properties
   */
  createTableRow(file, index) {
    const { key, validated, validatedFile, error, errorFile } = file;

    return (
      <tr
        key={'row' + index}
        id={'files-list-file' + index}
        data-key={key}
        data-validated={validatedFile}
        data-error={errorFile}
      >
        {Object.keys(file).map((val, index) => {
          // skip creating table data for 'key' attribute
          if (
            val === 'key' ||
            val === 'validated' ||
            val === 'validatedFile' ||
            val === 'documentTypeShorthand' ||
            val === 'error' ||
            val === 'errorFile'
          ) {
            return null;
          }
          // parse date
          if (val === 'uploadDate') {
            let d = new Date(file.uploadDate);
            return (
              <td key={index}>
                {d.toLocaleDateString()} {d.toLocaleTimeString()}
              </td>
            );
          }
          // set document type value to 'label' value of doc type
          if (val === 'documentType') {
            return <td key={index}>{file[val]}</td>;
          }
          return <td key={index}>{file[val]}</td>;
        })}
        <td>
          <Validating
            validated={typeof validated === 'undefined' ? false : validated}
            error={typeof error === 'undefined' ? false : error}
            inProgress={
              typeof error === 'undefined' && typeof validated === 'undefined'
            }
          ></Validating>
        </td>
        <td
          key={index}
          className='text-center row-actions'
        >
          <FileRowActions
            proposalSubmitted={this.props.proposalSubmitted}
            error={file.error}
            errorFile={file.errorFile}
            onErrorFileDownloadButtonClick={(e) =>
              helpers.onErrorFileDownloadButtonClick(
                e,
                config.buckets.wildfireOutput
              )
            }
            onFileDownloadButtonClick={(e) =>
              helpers.onFileDownloadButtonClick(e, config.buckets.private)
            }
            onFileDeleteButtonClick={(e) =>
              helpers.onFileDeleteButtonClick(
                e,
                config.buckets.private,
                this.props.refreshFileListCallback
              )
            }
            submissionEnded={this.props.submissionEnded}
          />
        </td>
      </tr>
    );
  }

  /**
   * Returns Withdraw Proposal button or Delete Proposal button depending on the proposalSubmitted state
   */
  showWithdrawProposalButton() {
    return (
      this.props.proposalSubmitted && (
        <Col>
          <Button
            variant='warning'
            className={classNames({
              shadow: true,
            })}
            size='lg'
            onClick={() => this.setState({ showWithdrawModal: true })}
          >
            <i className='bi bi-reply-all-fill mr-2'></i>
            Withdraw Proposal
          </Button>
          <Modal
            centered
            animation
            show={this.state.showWithdrawModal}
            onHide={() => this.setState({ showWithdrawModal: false })}
            id='withdraw-modal'
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to withdraw this proposal?
              <br />
              <br />
              <small className='text-muted'>
                A proposal must be withdrawn before any changes can be made to
                the proposal documents.
              </small>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                className='shadow'
                onClick={() => this.setState({ showWithdrawModal: false })}
              >
                Cancel
              </Button>
              <Button
                variant='warning'
                className='shadow'
                onClick={this.handleWithdrawProposal}
              >
                Withdraw Proposal
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      )
    );
  }

  /**
   * Sets customized message on Confirmation Popup,
   * if exhibit b or exhibit c files are missing
   */
  displayExhibitFileWarningMessage() {
    if (
      !helpers.checkValidExhibitBExists(this.props.proposalFiles) &&
      !helpers.checkValidExhibitCExists(this.props.proposalFiles)
    ) {
      return `No Exhibit B or C file detected.  
      Are you sure you want to submit this proposal?`;
    }
    if (!helpers.checkValidExhibitBExists(this.props.proposalFiles)) {
      return `No Exhibit B file detected.  
      Are you sure you want to submit this proposal?`;
    }
    return `Are you sure you want to submit this proposal?`;
  }

  render() {
    return (
      <div className='existing-file-section'>
        <Container>
          <ExistingFileListHeader proposalName={this.props.proposalName} />
          <ProposalSubmissionStatus
            proposalSubmitted={this.props.proposalSubmitted}
            proposalId={this.props.proposalId}
          />
          <Table
            className={classNames({
              'existing-file-list': true,
              'text-center': true,
              'mb-4': this.props.submissionEnded,
            })}
            hover
          >
            <thead>
              <tr>
                {this.state.tableHeaders.map((val, index) => (
                  <th key={index}>{val}</th>
                ))}
              </tr>
            </thead>
            <tbody>{this.props.proposalFiles && this.createMainTable()}</tbody>
          </Table>
          <div
            className={classNames({
              section: true,
              'd-none': this.props.submissionEnded,
            })}
          >
            <Row
              className={classNames({
                'text-center': true,
              })}
            >
              {this.showWithdrawProposalButton()}
              <Col>
                <Button
                  variant='primary'
                  type='submit'
                  className='shadow'
                  size='lg'
                  onClick={() => this.setState({ showSubmitModal: true })}
                  disabled={!this.enableSubmitButton()}
                >
                  {this.props.proposalSubmitted ? (
                    <span>
                      <i className='bi bi-check-circle mr-2'></i>
                      Proposal Submitted
                    </span>
                  ) : (
                    <span>
                      <i className='bi bi-check2-square mr-2'></i>
                      Submit Proposal
                    </span>
                  )}
                </Button>
              </Col>
            </Row>
          </div>

          <SubmissionInformation />
          {/* Proposal Submit Confirmation Modal */}
          <Modal
            centered
            animation
            show={this.state.showSubmitModal}
            onHide={() => this.setState({ showSubmitModal: false })}
            id='submit-modal'
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.displayExhibitFileWarningMessage()}
              <br />
              <br />
              Before the proposal due date of 11:59 PM PST on March 17, 2023,
              you may withdraw or resubmit the proposal if needed.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                className='shadow'
                onClick={() => this.setState({ showSubmitModal: false })}
              >
                Cancel
              </Button>
              {this.state.submittingProposal ? (
                <Button
                  variant='primary'
                  className='shadow'
                  disabled={true}
                  // onClick={this.handleSubmitProposal}
                >
                  <span>
                    <Spinner
                      className='mr-2'
                      animation='grow'
                      size='sm'
                    />
                    Submitting Proposal
                  </span>
                </Button>
              ) : (
                <Button
                  variant='primary'
                  className='shadow'
                  onClick={this.handleSubmitProposal.bind(this)}
                >
                  Submit Proposal
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          {/* Upload Error Modal */}
          <Modal
            centered
            animation
            show={this.state.showSubmitProposalError}
            onHide={() => this.setState({ showSubmitProposalError: false })}
            id='submit-modal'
          >
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                There was an issue while submitting the proposal. Please retry
                submitting this proposal.
              </p>
              <p>
                If issue persists, please use Contact Us link in footer for
                assistance.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='primary'
                className='shadow'
                onClick={() =>
                  this.setState({ showSubmitProposalError: false })
                }
              >
                Okay
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Proposal Submission Confirmation Modal */}
          <Modal
            centered
            // animation
            show={this.state.showProposalSubmittedConfirmation}
            onHide={() =>
              this.setState({ showProposalSubmittedConfirmation: false })
            }
            id='submit-modal'
          >
            <Modal.Header closeButton>
              <Modal.Title>Proposal Submitted</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Proposal submitted. A confirmation email will be sent to your
                registered email.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='primary'
                className='shadow'
                onClick={() =>
                  this.setState({ showProposalSubmittedConfirmation: false })
                }
              >
                Okay
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

ExistingFileList.propTypes = {
  proposalName: PropTypes.object.isRequired,
  documentOptions: PropTypes.array.isRequired,
  fileList: PropTypes.array.isRequired,
  proposalFiles: PropTypes.array.isRequired,
};

export default ExistingFileList;
