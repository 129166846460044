import React, { Component } from 'react';
// component styles
// import './index.css';
// bootstrap components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class ExistingFileListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="text-center section"
      >
        <h2
          className="section-header"
        >
          Files Uploaded for <span className="s2 text-capitalize">{this.props.proposalName.label}</span> proposal
        </h2>
        <Row>
          <Col>
            <p className="support-copy">
              Uploading a new file with same name and document type as an existing file will replace the existing file
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ExistingFileListHeader;

