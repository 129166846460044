import React from 'react';
import PropTypes from 'prop-types';
// component styles
import './index.css';
// bootstrap components
import Button from 'react-bootstrap/Button'

const FileRowActions = ({
  proposalSubmitted,
  error,
  errorFile,
  onErrorFileDownloadButtonClick,
  onFileDownloadButtonClick,
  onFileDeleteButtonClick,
  submissionEnded
}) => {
  return (<>
    {error &&
      <Button
        variant="outline-danger"
        className="m-1 shadow"
        title="Download Errors List"
        onClick={onErrorFileDownloadButtonClick}
        data-key={errorFile}
        href="#"
      >
        <i className="bi bi-download"></i>
      </Button>
    }
    <Button
      variant="outline-success"
      className="m-1 shadow"
      title="Download File"
      onClick={onFileDownloadButtonClick}
      href="#"
    >
      <i className="bi bi-download"></i>
    </Button>
    {
      proposalSubmitted || submissionEnded ?
        <Button
          variant="secondary"
          className="m-1 shadow"
          title="Delete File"
          disabled
        >
          <i className="bi bi-x-circle"></i>
        </Button>
        : <Button
          variant="outline-danger"
          className="m-1 shadow"
          title="Delete File"
          onClick={onFileDeleteButtonClick}
        >
          <i className="bi bi-x-circle"></i>
        </Button>
    }
  </>)
}

FileRowActions.propTypes = {
  proposalSubmitted: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  errorFile: PropTypes.string,
  onErrorFileDownloadButtonClick: PropTypes.func.isRequired,
  onFileDownloadButtonClick: PropTypes.func.isRequired,
  onFileDeleteButtonClick: PropTypes.func.isRequired,
}

export default FileRowActions

