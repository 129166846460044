// npm modules
import React from 'react';
import Amplify from 'aws-amplify';
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import classNames from 'classnames';
// components
import Main from './page/Main';
import Header from './page/Header';
import Footer from './page/Footer';
import Modal from 'react-bootstrap/Modal';
// import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import ConsoleHelper from './helpers/ConsoleHelper';
import moment from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
// page styles
import './App.css';
import '@fontsource/roboto';
// config
import awsconfig from './config/aws-settings';

Amplify.configure(awsconfig.cognito);

function App() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const [showLogoutNotification, setShowLogoutNotification] = React.useState();
  const [alert, setAlert] = React.useState(null);
  const [alertShowed, setAlertShowed] = React.useState(false);

  /**
   * Disable portal code
   *
   * Date calculations to determine when the site should be unlocked and locked
   * - submissionStart = when the site should be unlocked and available for use
   * - SubmissionDeadline = when site should be closed
   */

  // Closing web portal
  // ------------------

  // const submissionDeadline = '2023-01-16 23:59:59'; // date to test warning and closing features
  const submissionDeadline = '2023-03-17 23:59:59';
  const submissionPeriodEndTime = moment(
    submissionDeadline,
    'YYYY-MM-DD h:mm:ss'
  ).tz('America/Los_Angeles');

  // Calculate when to show the portal closing warning message
  const submissionWarning =
    moment().diff(submissionPeriodEndTime, 'days') >= -7; // controls when 'Sign in' options get disabled
  // Calculate when to close portal
  const submissionsEnded =
    moment().diff(submissionPeriodEndTime, 'seconds') >= 0; // controls when 'Sign in' options get disabled

  // const submissionStartDate = '2023-01-18 00:00:00';  // date to test opening of site features
  const submissionStartDate = '2023-01-23 00:00:00';
  const submissionPeriodStartTime = moment(
    submissionStartDate,
    'YYYY-MM-DD h:mm:ss'
  ).tz('America/Los_Angeles');

  // Calculate when to show the portal opening message
  const submissionsStart =
    moment().diff(submissionPeriodStartTime, 'seconds') >= 0; // controls when 'Sign in' options get disabled

  //
  // Disable portal code - end
  //

  React.useEffect(() => {
    ConsoleHelper(bugFixClass);
    return onAuthUIStateChange((nextAuthState, authData) => {
      ConsoleHelper('APP :: auth state changed');
      ConsoleHelper('nextAuthState');
      ConsoleHelper(nextAuthState);
      ConsoleHelper('authData');
      ConsoleHelper(authData);
      ConsoleHelper('authData');
      setAuthState('nextAuthState');
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  React.useEffect(() => {
    if (submissionsEnded) {
      titleAndTextAlert();
      setAlertShowed(true);
    }
  }, [alertShowed]);

  React.useEffect(() => {
    if (submissionsStart) {
      // titleAndTextAlert();
      setAlertShowed(true);
    }
  }, [alertShowed]);

  function updateUserCallBack(state) {
    ConsoleHelper('setting user');
    ConsoleHelper(state);
    setUser(state);
  }
  function updateAuthStateCallBack(state) {
    ConsoleHelper('setting auth state');
    ConsoleHelper(state);
    setAuthState(state);
  }

  function updateShowLogoutNotificationCallBack(state) {
    setShowLogoutNotification(state);
  }

  /**
   * These styles is to address the auth-container styling issue with this version of the aws-amplify.
   * The container does not accept overrides from components.
   * The custom styles are for authentication pages that fit all content on a screen without scrolling
   */
  const bugFixClass = {
    rootParentContainer: classNames({
      App: true,
      'd-flex': true,
      'flex-column': true,
      'h-100': true,
      'overflow-hidden':
        authState === AuthState.SignIn ||
        authState === AuthState.ForgotPassword ||
        authState === AuthState.SignedOut ||
        authState === AuthState.ConfirmSignUp,
    }),
    card2: classNames({
      card2:
        authState === AuthState.SignIn ||
        authState === AuthState.ForgotPassword ||
        authState === AuthState.SignedOut ||
        authState === AuthState.ConfirmSignUp,
    }),
    customAppContainer: classNames({
      'custom-amp-container':
        authState === AuthState.SignIn ||
        authState === AuthState.ForgotPassword ||
        authState === AuthState.SignedOut ||
        authState === AuthState.ConfirmSignUp,
    }),
  };

  const hideAlert = () => {
    setAlert(null);
  };

  /**
   * Website alert describing the website closure and
   * limited site functionality
   */
  const titleAndTextAlert = () => {
    setAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title='Thank you!'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle='info'
      >
        <p>
          The submission portal for proposals has been closed as of{' '}
          <b>{submissionPeriodEndTime.format('MMMM D, YYYY, h:mm:ss A z')}.</b>
        </p>
        <p>
          Access to proposals are still available, but withdrawals and
          resubmissions have been disabled. We look forward to future
          opportunities to work with you!
        </p>
      </SweetAlert>
    );
  };

  return (
    <div className={bugFixClass.rootParentContainer}>
      <Alert
        variant='warning'
        className={classNames({
          'd-none': !submissionWarning,
          'text-center': true,
          'mb-0': true,
          'sticky-top': true,
        })}
      >
        {`PSE’s 2023 DSS RFP submissions window is closing on 
        ${submissionPeriodEndTime.format('MMMM D, YYYY, h:mm:ss A zz')}.  
        Please submit proposals before the deadline.`}
      </Alert>

      <Alert
        variant='warning'
        className={classNames({
          'd-none': !submissionsEnded,
          'text-center': true,
          'mb-0': true,
          'sticky-top': true,
        })}
      >
        {`Thank you for participating in PSE’s 2023 DSS RFP.  Submissions window has closed as of ${submissionPeriodEndTime.format(
          'MMMM D, YYYY, h:mm:ss A zz'
        )}`}
      </Alert>

      <Alert
        variant='warning'
        className={classNames({
          'd-none': submissionsStart,
          'text-center': true,
          'mb-0': true,
          'sticky-top': true,
        })}
      >
        {`Submissions will begin ${submissionPeriodStartTime.format(
          'MMMM D, YYYY, h:mm:ss A zz'
        )}`}
      </Alert>

      <Header
        authState={authState}
        user={user}
      />
      <main
        className={classNames({
          'd-flex': authState !== AuthState.SignedIn,
          'justify-content-center': authState !== AuthState.SignedIn,
        })}
      >
        <div className={bugFixClass.card2}>
          <div className={bugFixClass.customAppContainer}>
            <AmplifyAuthenticator
              usernameAlias='email'
              className='d-flex flex-column'
              initialAuthState={AuthState.SignIn}
            >
              <AmplifySignUp
                headerText='Create An Account to Track Your Submissions'
                usernameAlias='email'
                slot='sign-up'
                formFields={[
                  {
                    type: 'email',
                    label: 'Email * (Username)',
                    placeholder: 'Enter your email',
                    required: true,
                  },
                  {
                    type: 'password',
                    label:
                      'Password * (At least 8 chars, upper & lower case, a number and a symbol)',
                    placeholder: 'Enter your new password',
                    required: true,
                  },
                  {
                    type: 'custom:organization',
                    label: 'Organization *',
                    placeholder: 'Enter the Name of your Organization',
                    required: true,
                  },
                  {
                    type: 'name',
                    label: 'Full Name *',
                    placeholder: 'Enter Your Name',
                    required: true,
                  },
                  {
                    type: 'custom:jobtitle',
                    label: 'Job Title *',
                    placeholder: 'Enter Your Job Title',
                    required: true,
                  },
                  {
                    type: 'phone_number',
                    label: 'Phone Number *',
                    placeholder: 'Enter your Phone Number',
                    required: true,
                  },
                ]}
              ></AmplifySignUp>

              <AmplifyForgotPassword
                slot='forgot-password'
                usernameAlias='email'
                sendButtonText='Send Code'
                submitButtonText='Submit Password'
              ></AmplifyForgotPassword>

              <Main
                updateUserCallBack={updateUserCallBack}
                updateAuthStateCallBack={updateAuthStateCallBack}
                updateShowLogoutNotificationCallBack={
                  updateShowLogoutNotificationCallBack
                }
                // user={user}
                authState={authState}
                submissionsEnded={submissionsEnded}
                submissionsStart={!submissionsStart}
              />
            </AmplifyAuthenticator>
            {alert}
          </div>
        </div>
        {
          // logout notification modal
        }
        <Modal
          show={showLogoutNotification}
          animation
          onHide={() => setShowLogoutNotification(false)}
          centered
        >
          <Modal.Header>
            <Modal.Title>Session expired</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Session has expired due to inactivity. Please sign back in.
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </main>
      <Footer
        user={user}
        authState={authState}
      />
    </div>
  );
}

export default App;
