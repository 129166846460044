/**
 * @file functions for the ExistingFileList component.  Separated to reduce length of index
 */

import _ from 'lodash';
import { Storage } from 'aws-amplify';

/**
 * Checks current proposal files if an exhibit B exists
 * @param {collection} proposalFiles - array of files related to current displayed proposal
 * @returns boolean if current proposal files contain an exhibit B
 */
export function checkValidExhibitBExists(proposalFiles) {
  let exhibitBFile =
    _.filter(proposalFiles, (file) => {
      return file.props['document-type-shorthand'] === 'b'
    });
  if (exhibitBFile.length > 0) {
    return true
  }
  return false
}

/**
 * Checks current proposal files if an exhibit B exists
 * @param {collection} proposalFiles - array of files related to current displayed proposal
 * @returns boolean if current proposal files contain an exhibit B
 */
export function checkValidExhibitCExists(proposalFiles) {
  let exhibitBFile =
    _.filter(proposalFiles, (file) => {
      return file.props['document-type-shorthand'] === 'c'
    });
  if (exhibitBFile.length > 0) {
    return true
  }
  return false
}

/**
 * Checks to see if all proposal files have been validated
 * @param {collection} proposalFiles - array of files related to current displayed proposal
 * @returns boolean if all proposal files have props.validated = true
 */
export function checkUploadedFilesValid(proposalFiles) {
  let filesNotValidated =
    _.filter(proposalFiles, ['validated', false]);

  if (filesNotValidated.length === 0) {
    return true
  }
  return false
}

/**
 * Deletes provided key from user's private bucket
 * @param {string} bucket - aws bucket
 * @param {string} key - key to delete from aws
 * @returns Promise function to delete key from user's private bucket
 */
export function deleteFileFromPrivateBucket(bucket, key) {

  if (key !== null && key !== '') {
    return new Promise((resolve, reject) => {
      // point to bucket to download file from
      Storage.configure({
        AWSS3: {
          bucket: bucket,
          region: 'us-west-2',
        },
        level: 'private',
      });

      Storage.remove(key)
        .then((result) => {
          if (result.$metadata.httpStatusCode === 204) {
            resolve(`${key} deleted`)
          }
        });
    })
  }
  return Promise.resolve('key empty')
}

/**
 * Deletes file from wildfire bucket
 * @param {string} bucket - aws bucket
 * @param {string} key - key to delete from wildfire bucket
 * @returns Promise to delete file from bucket
 */
export function deleteFileFromWildfireBucket(bucket, key) {

  if (key !== null && key !== '') {
    return new Promise((resolve, reject) => {
      // point to bucket to download file from
      Storage.configure({
        AWSS3: {
          bucket: bucket,
          region: 'us-west-2',
        },
        level: 'public',
      });

      Storage.remove(key)
        .then((result) => {
          if (result.$metadata.httpStatusCode === 204) {
            resolve(`${key} deleted`)
          }
        });
    })
  }
  return Promise.resolve('key empty')
}

/**
 * gets a download link from AWS and proceeds to download the file
 * @param {object} e - the click event
 * @param {string} bucket - aws bucket
 */
export async function onFileDownloadButtonClick(e, bucket) {
  let key = e.target.closest('tr').dataset.key;

  // point to bucket to download file from
  Storage.configure({
    AWSS3: {
      bucket: bucket,
      region: 'us-west-2',
    },
    level: 'private',
  });

  // get the signed URL string
  Storage.get(key)
    .then((result) => {
      window.open(result, '_blank')
    });
}

/**
* gets a download link from AWS and proceeds to download the file
* @param {object} e - the click event
* @param {string} bucket - aws bucket
*/
export async function onErrorFileDownloadButtonClick(e, bucket) {
  let errorFile = e.target.closest('tr').dataset.error;

  // point to bucket to download file from
  Storage.configure({
    AWSS3: {
      bucket: bucket,
      region: 'us-west-2',
    },
    level: 'public',
  });

  // get the signed URL string
  Storage.get(errorFile)
    .then((result) => {
      window.open(result, '_blank')
    });
}

/**
 * deletes user's file from aws bucket.
 * @param {object} e - click event object
 * @param {string} bucket - aws bucket
 * @param {func} callback - callback function
 */
export async function onFileDeleteButtonClick(e, bucket, callback) {
  let fileKey = e.target.closest('tr').dataset.key;

  Promise.allSettled([
    // helpers.deleteFileFromPrivateBucket(privateBucket, fileKey),
    deleteFileFromPrivateBucket(bucket, fileKey),
  ]).then((results) => {
    // this.props.refreshFileListCallback()
    callback()
  })
}

