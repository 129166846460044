import React, { Component } from 'react';
// bootstrap components
import Alert from 'react-bootstrap/Alert';
// import Col from 'react-bootstrap/Col';

class ProposalSubmissionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Alert
        variant={this.props.proposalSubmitted ? 'success' : 'info'}
        className="mb-4 d-flex"
      >
        <div>
          {this.props.proposalSubmitted ?
            <span>
              <i className="bi bi-info-circle mr-2"></i>
              Proposal submitted.
            </span> :
            <span>
              <i className="bi bi-info-circle mr-2"></i>
              Proposal not submitted.
            </span>}
        </div>
        {this.props.proposalSubmitted && <div className="ml-auto">
          Proposal ID: <u>{this.props.proposalId ? this.props.proposalId.replace('t-', '') : ""}</u>
        </div>}
      </Alert>
    );
  }
}

export default ProposalSubmissionStatus;

