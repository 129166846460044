import React, { Component } from "react";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AuthState } from "@aws-amplify/ui-components";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerLinksAboutUs: [
        { label: "PSE.com", href: "http://www.pse.com/" },
        { label: "Acquiring Energy", href: "http://pse.com/rfp" },
      ],
      footerLinksSelfService: [
        {
          label: "RFP Submission User Guide",
          href: `${process.env.PUBLIC_URL}/documents/DSS RFP Automation - Users Guide.pdf`,
        },
        { label: "Contact Us", href: "mailto:solarstorageRFPmailbox@pse.com" },
      ],
    };
  }

  createList(title, arr) {
    return (
      <>
        <h5>{title}</h5>
        <ul className="list-unstyled text-small">
          {arr.map((obj, ind) => {
            let { label, href } = obj;
            return (
              <li key={ind}>
                <a className="text-decoration-none" href={href}>
                  {label}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  render() {
    return (
      <footer
        className={
          this.props.authState === AuthState.SignedIn
            ? "container-fluid text-center section"
            : "container-fluid text-center section mt-auto"
        }
      >
        <Container>
          <Row>
            <Col md={6}>
              {this.createList("About Us", this.state.footerLinksAboutUs)}
            </Col>
            <Col md={6}>
              {this.createList("Help", this.state.footerLinksSelfService)}
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <p>
                <small>
                  RFP Policy Notice: PSE personnel will not accept calls,
                  emails, or any other method of communication regarding this
                  RFP other than to the email provided through Contact Us. All
                  emails sent to this address will be shared with PSE’s 2023 DSS 
                  RFP Independent Evaluator, Bates White. Any questions sent to
                  the mailbox will be anonymized and posted to the RFP website
                  in the Q&As for the benefit of all bidders.
                </small>
              </p>
            </Col>
          </Row>
        </Container>
        <div className="text-center text-muted small">v0.3.0</div>
      </footer>
    );
  }
}

export default Footer;
