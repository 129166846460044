/**
 * Change AWS Cognito Information here.
 * S3 Buckets and other infrastructure is built using Terraform.
 * If those change, these need to be updated.
 */

// config

const environment = 'dev';
// const environment = 'qa';
// const environment = 'prod';

let awsconfig = '';

switch (environment) {
  case 'dev':
    awsconfig = {
      cognito: {
        Auth: {

          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          identityPoolId: 'us-west-2:387fd94a-1ed2-4386-b09b-fd25df666113',  // DEV

          // REQUIRED - Amazon Cognito Region
          region: 'us-west-2',

          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: 'us-west-2_3GYX8ThfC', // DEV

          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId: '3jl3hlosa59a73dcp7d222p7gg', // DEV

          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          mandatorySignIn: true,
        },
      },
      buckets: {
        private: 'pse-gen-rfp-upload-bucket-3b6aff89071e2180-dev',
        wildfireOutput: 'pse-gen-rfp-testupload-dev',
      }
    };
    break;
  case 'qa':
    awsconfig = {
      cognito: {
        Auth: {

          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          identityPoolId: 'us-west-2:95be249e-97be-42b2-a7ba-0f56af64f053',  // QA

          // REQUIRED - Amazon Cognito Region
          region: 'us-west-2',

          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: 'us-west-2_L8nRkl8N0', // QA

          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId: '1skkucc7ahnt3e57c586g5r31b', // QA

          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          mandatorySignIn: true,
        },
      },
      buckets: {
        private: 'pse-gen-rfp-upload-bucket-56412fd74778f041-qa',
        wildfireOutput: 'pse-gen-rfp-tmp-qa',
      }
    };
    break;
  case 'prod':
    awsconfig = {
      cognito: {
        Auth: {

          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          identityPoolId: 'us-west-2:56bfe39e-e02b-41e2-9cf1-5db4f67a975b', // Prod

          // REQUIRED - Amazon Cognito Region
          region: 'us-west-2',

          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: 'us-west-2_pgirCnWmt',  // Prod

          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId: '3dk9kedc77rhns95ir2889irag',  // Prod

          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          mandatorySignIn: true,
        },
      },
      buckets: {
        private: 'pse-gen-rfp-upload-bucket-cea915b56921cf9b-prod',
        wildfireOutput: 'pse-gen-rfp-tmp-prod',
      }
    };
    break;
  default:
    console.log(`unknown environment, ${environment}`);
}

export default awsconfig;